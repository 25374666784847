import {
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import resetStore from 'store/action';

const SessionTimeout = ({ timeoutDuration }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [counter, setCounter] = useState(45);

	const logout = useCallback(() => {
		onClose();
		resetStore(dispatch);
		navigate('/auth/sign-in');
	}, [isLoggedIn, navigate, dispatch]);

	useEffect(() => {
		let timer;
		let countdownInterval;

		const startCountdown = () => {
			setCounter(45);
			onOpen();
			countdownInterval = setInterval(() => {
				setCounter((prevCounter) => {
					if (prevCounter <= 1) {
						clearInterval(countdownInterval);
						logout();
					}
					return prevCounter - 1;
				});
			}, 1000);
		};

		const resetTimer = () => {
			clearTimeout(timer);
			clearInterval(countdownInterval);
			onClose();
			timer = setTimeout(startCountdown, timeoutDuration);
		};
		const handleActivity = () => {
			resetTimer();
		};

		if (isLoggedIn) {
			resetTimer();
			window.addEventListener('mousemove', handleActivity);
			window.addEventListener('keydown', handleActivity);
			window.addEventListener('scroll', handleActivity);
		}

		// Clean up on component unmount
		return () => {
			clearTimeout(timer);
			clearInterval(countdownInterval);
			window.removeEventListener('mousemove', handleActivity);
			window.removeEventListener('keydown', handleActivity);
			window.removeEventListener('scroll', handleActivity);
		};
	}, [isLoggedIn, logout, onClose, onOpen, timeoutDuration]);

	return (
		<Modal isOpen={isOpen && isLoggedIn} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader mt={7}>Advertencia de tiempo de sesión</ModalHeader>
				<ModalBody mb={10}>
					<Text>
						Tu sesión expirará en {counter} segundos debido a inactividad.
					</Text>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default SessionTimeout;
