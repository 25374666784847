import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
	userId: null,
	tokenId: null,
	isLoggedIn: false,
};

const authSlice = createSlice({
	name: 'authentication',
	initialState: initialAuthState,
	reducers: {
		setUserId(state, action) {
			state.userId = action.payload;
		},
		setTokenId(state, action) {
			state.tokenId = action.payload;
		},
		setIsLoggedIn(state, action) {
			state.isLoggedIn = action.payload;
		},
		reset() {
			return initialAuthState;
		},
	},
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
