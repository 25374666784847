// Chakra imports
import { QuestionIcon } from '@chakra-ui/icons';
import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Button,
	CircularProgress,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	PinInput,
	PinInputField,
	Text,
	Tooltip,
	useColorModeValue,
} from '@chakra-ui/react';
import { resetPassword, validateOtp } from 'api/user';
import { ERROR, SUCCESS } from 'constant/messages';
import CenteredAuth from 'layouts/auth/types/Centered';
import { useState } from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
	const textColor = useColorModeValue('gray.900', 'gray.100');
	const textColorSecondary = useColorModeValue('gray.900', 'gray.100');
	const borderColor = useColorModeValue('gray.900', 'gray.100');
	const textColorBrand = useColorModeValue('brand.500', 'gray.100');
	const brandStars = useColorModeValue('brand.500', 'brand.400');
	const navigate = useNavigate();

	const [code, setCode] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [message, setMessage] = useState(false);
	const [isOtpSubmitted, setIsOtpSubmitted] = useState(false);
	const [isNewPasswordSubmitted, setIsNewPasswordSubmitted] = useState(false);
	const [isOtpValid, setIsOtpValid] = useState(false);
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);

	const handleFocus = () => {
		setMessage({
			status: '',
			state: false,
			description: '',
		});
		setIsNewPasswordSubmitted(false);
		setIsOtpSubmitted(false);
	};

	const handleClick = () => setShow(!show);

	const validatePassword = (password) => {
		const hasUppercase = /[A-Z]/.test(password);
		const hasLowercase = /[a-z]/.test(password);
		const hasNumber = /[0-9]/.test(password);
		return password.length >= 8 && hasUppercase && hasLowercase && hasNumber;
	};

	const handleValidateOtp = async (e) => {
		e.preventDefault();
		setIsOtpSubmitted(false);
		setMessage({ status: '', state: false, description: '' });
		setLoading(true);

		try {
			if (code) {
				const response = await validateOtp(code);
				const result = await response.json();
				if (result.status === 'SUCCESS') {
					setMessage({
						status: 'success',
						state: true,
						description: SUCCESS.VALIDATE_CODE,
					});
					setIsOtpSubmitted(true);
					setIsOtpValid(true);
				} else {
					setMessage({
						status: 'error',
						state: true,
						description: ERROR.VALID_CODE,
					});
				}
			} else {
				setMessage({
					status: 'error',
					state: true,
					description: ERROR.VALID_CODE,
				});
			}
		} catch (error) {
			setMessage({
				status: 'error',
				state: true,
				description: ERROR.SOMETHING_WRONG,
			});
			console.error('Error:', error);
		} finally {
			setIsOtpSubmitted(true);
			setLoading(false);
		}
	};

	const handleNewPassword = async (e) => {
		e.preventDefault();
		setIsNewPasswordSubmitted(false);
		setMessage({ state: false, description: '' });
		setLoading(true);
		let isPasswordValid = validatePassword(newPassword);
		try {
			if (code && isPasswordValid) {
				const response = await resetPassword(code, newPassword);
				const result = await response.json();
				if (result.status === 'SUCCESS') {
					setMessage({
						status: 'success',
						state: true,
						description: SUCCESS.RESET_PASSWORD,
					});
					setSuccess(true);
				} else {
					setMessage({
						status: 'error',
						state: true,
						description: ERROR.SOMETHING_WRONG,
					});
				}
			} else {
				setMessage({
					status: 'error',
					state: true,
					description: ERROR.VALID_PASSWORD,
				});
			}
		} catch (error) {
			setMessage({
				status: 'error',
				state: true,
				description: ERROR.SOMETHING_WRONG,
			});
			console.error('Error:', error);
		} finally {
			setIsNewPasswordSubmitted(true);
			setLoading(false);
		}
	};

	const handleValidateOtpKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleValidateOtp(e);
		}
	};

	const handleNewPasswordKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleNewPassword(e);
		}
	};

	const handleLogin = async () => {
		navigate('/auth/sign-in');
		setSuccess(false);
		setIsOtpValid(false);
		setMessage({
			status: '',
			state: false,
			description: '',
		});
	};

	return (
		<CenteredAuth
			cardTop={{ base: '140px', md: '24vh' }}
			cardBottom={{ base: '50px' }}
		>
			<Flex
				w='100%'
				maxW='max-content'
				mx={{ base: 'auto', lg: '0px' }}
				me='auto'
				h='100%'
				justifyContent='center'
				alignItems='center'
				px={{ base: '25px', md: '0px' }}
				flexDirection='column'
			>
				<Box w='100%' me='auto' my='34px'>
					<Heading
						color={textColor}
						fontSize={{ base: '24px', md: '36px' }}
						mb='16px'
						textAlign='center'
					>
						Restablecer contraseña
					</Heading>
					{!isOtpValid && !success && (
						<Text color={textColorSecondary} fontSize='md' textAlign='center'>
							Ingresa tu código OTP para proceder
						</Text>
					)}
					{isOtpValid && !success && (
						<Text color={textColorSecondary} fontSize='md' textAlign='center'>
							Ingresa tu nueva contraseña
						</Text>
					)}
				</Box>
				{loading && (
					<Flex mb='5' justifyContent={'center'}>
						<CircularProgress isIndeterminate color={textColorBrand} />
					</Flex>
				)}
				{!loading && message.state && (
					<Alert mb='5' status={message.status}>
						<AlertIcon />
						<AlertDescription>{message.description}</AlertDescription>
					</Alert>
				)}
				<Flex
					zIndex='2'
					direction='column'
					w={{ base: '100%', md: '425px' }}
					maxW='100%'
					background='transparent'
					borderRadius='15px'
					mx={{ base: 'auto', lg: 'unset' }}
					me='auto'
					mb={{ base: '20px', md: 'auto' }}
				>
					{!isOtpValid && !success && (
						<FormControl>
							<Flex justify='center'>
								<PinInput
									mx='auto'
									otp
									onKeyDown={handleValidateOtpKeyDown}
									onChange={(code) => setCode(code)}
								>
									<PinInputField
										onFocus={handleFocus}
										fontSize='36px'
										color={textColor}
										borderRadius='16px'
										borderColor={
											isOtpSubmitted && !code ? 'red.500' : borderColor
										}
										h={{ base: '63px', md: '95px' }}
										w={{ base: '63px', md: '95px' }}
										me='10px'
									/>
									<PinInputField
										onFocus={handleFocus}
										fontSize='36px'
										color={textColor}
										borderRadius='16px'
										borderColor={
											isOtpSubmitted && !isOtpValid && !code
												? 'red.500'
												: borderColor
										}
										h={{ base: '63px', md: '95px' }}
										w={{ base: '63px', md: '95px' }}
										me='10px'
									/>
									<PinInputField
										onFocus={handleFocus}
										fontSize='36px'
										color={textColor}
										borderRadius='16px'
										borderColor={
											isOtpSubmitted && !isOtpValid && !code
												? 'red.500'
												: borderColor
										}
										h={{ base: '63px', md: '95px' }}
										w={{ base: '63px', md: '95px' }}
										me='10px'
									/>
									<PinInputField
										onFocus={handleFocus}
										fontSize='36px'
										color={textColor}
										borderRadius='16px'
										borderColor={
											isOtpSubmitted && !isOtpValid && !code
												? 'red.500'
												: borderColor
										}
										h={{ base: '63px', md: '95px' }}
										w={{ base: '63px', md: '95px' }}
										me='10px'
									/>
									<PinInputField
										onFocus={handleFocus}
										fontSize='36px'
										color={textColor}
										borderRadius='16px'
										borderColor={
											isOtpSubmitted && !isOtpValid && !code
												? 'red.500'
												: borderColor
										}
										h={{ base: '63px', md: '95px' }}
										w={{ base: '63px', md: '95px' }}
										me='10px'
									/>
									<PinInputField
										onFocus={handleFocus}
										fontSize='36px'
										color={textColor}
										borderRadius='16px'
										borderColor={
											isOtpSubmitted && !isOtpValid && !code
												? 'red.500'
												: borderColor
										}
										h={{ base: '63px', md: '95px' }}
										w={{ base: '63px', md: '95px' }}
									/>
								</PinInput>
							</Flex>
							<Button
								fontSize='14px'
								variant='brand'
								borderRadius='16px'
								fontWeight='500'
								w='100%'
								h='50'
								mb='24px'
								mt='25px'
								onClick={handleValidateOtp}
							>
								Validar
							</Button>
						</FormControl>
					)}
					{isOtpValid && !success && (
						<FormControl>
							<Flex
								mt='25px'
								flexDirection='column'
								alignItems='center'
								justify='center'
							>
								<Tooltip
									label={
										'La contraseña debe tener al menos una mayúscula, una minúscula y un número.'
									}
									aria-label='A tooltip'
								>
									<FormLabel
										textAlign='left'
										display='flex'
										alignItems='center'
										ms='4px'
										fontSize='sm'
										fontWeight='500'
										color={textColor}
										mb='8px'
										pl='8px'
										w='100%'
									>
										Contraseña nueva{' '}
										<QuestionIcon ml='0.5rem' color={brandStars} />
									</FormLabel>
								</Tooltip>
								<InputGroup size='md'>
									<Input
										id='password'
										onFocus={handleFocus}
										variant='auth'
										fontSize='sm'
										name='password'
										onKeyDown={handleNewPasswordKeyDown}
										value={newPassword}
										onChange={(e) => setNewPassword(e.target.value)}
										ms={{ base: '0px', md: '4px' }}
										placeholder='Contraseña nueva'
										mb='24px'
										size='lg'
										type={show ? 'text' : 'password'}
										borderColor={
											isNewPasswordSubmitted && !validatePassword(newPassword)
												? 'red.500'
												: null
										}
									/>
									<InputRightElement
										display='flex'
										alignItems='center'
										mt='4px'
									>
										<Icon
											color={textColorSecondary}
											_hover={{ cursor: 'pointer' }}
											as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
											onClick={handleClick}
										/>
									</InputRightElement>
								</InputGroup>
							</Flex>
							<Button
								fontSize='14px'
								variant='brand'
								borderRadius='16px'
								fontWeight='500'
								w='100%'
								h='50'
								mb='24px'
								mt='12px'
								onClick={handleNewPassword}
							>
								Restablecer contraseña
							</Button>
						</FormControl>
					)}
					{isOtpValid && success && (
						<FormControl>
							<Button
								fontSize='14px'
								variant='brand'
								borderRadius='16px'
								fontWeight='500'
								w='100%'
								h='50'
								mb='24px'
								mt='12px'
								onClick={handleLogin}
							>
								Iniciar sesión
							</Button>
						</FormControl>
					)}
				</Flex>
			</Flex>
		</CenteredAuth>
	);
}

export default ForgotPassword;
