import { ChakraProvider } from '@chakra-ui/react';
import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SessionTimeout from 'utils/SessionTimeout';
import './assets/css/App.css';
import AdminLayout from './layouts/admin';
import AuthLayout from './layouts/auth';
import ClientLayout from './layouts/client';
import initialTheme from './theme/theme';

export default function Main() {
	const [currentTheme, setCurrentTheme] = useState(initialTheme);
	return (
		<ChakraProvider theme={currentTheme}>
			{/* Set session timeout to 30 minutes (1800000 milliseconds) */}
			<SessionTimeout
				timeoutDuration={process.env.REACT_APP_SESSION_TIMEOUT_MILLISECONDS}
			/>

			<Routes>
				<Route path='auth/*' element={<AuthLayout />} />
				<Route
					path='admin/*'
					element={
						<AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
					}
				/>
				<Route
					path='client/*'
					element={
						<ClientLayout theme={currentTheme} setTheme={setCurrentTheme} />
					}
				/>
				<Route path='/' element={<Navigate to='/auth' replace />} />
			</Routes>
		</ChakraProvider>
	);
}
