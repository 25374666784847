import { authActions } from './auth';
import { errorActions } from './errors';
import { persistor } from './index';
import { userActions } from './user';

export const resetStore = (dispatch, persist = true) => {
	dispatch(authActions.reset());
	dispatch(userActions.reset());
	dispatch(errorActions.reset());
	if (persist) {
		persistor.purge();
	}
};

export default resetStore;
